<template>
  <div class="farmers-basic-information">
    <x-table
      :columns="table.columns"
      :data="table.data"
      :loading="table.loading"
      :config="config"
      @page-change="pageChange"
      @loadEnter="loadEnter"
      @loadExpend="loadExpend"
      @search="search"
      @page-size-change="pageSizeChange"
      :showSummary="true"
      :summaryMethod="handleSummary"
    ></x-table>
  </div>
</template>

<script>
import CU from "@/common/util";
import { mapState } from "vuex";
export default {
  name: "",
  data() {
    return {
      table: {
        columns: [
          {
            title: "养殖户名称",
            minWidth: 120,
            key: "farmerName",
            fixed: 'left'
          },
          {
            title: "地址(村、社区)",
            minWidth: 300,
            key: "farmerAddress",
          },
          {
            title: "业主姓名",
            minWidth: 100,
            key: "name",
          },
          {
            title: "联系电话",
            minWidth: 130,
            key: "tel",
          },
          {
            title: "畜禽种类",
            minWidth: 100,
            key: "typeName",
          },
          {
            title: "畜禽代码",
            minWidth: 170,
            key: "livestockCode",
          },
          {
            title: "养殖用途",
            minWidth: 160,
            key: "application",
          },
          {
            title: "圈舍面积",
            minWidth: 100,
            key: "housingArea",
          },
          {
            title: "设计规模(头、羽、只)",
            minWidth: 180,
            key: "designNum",
          },
          {
            title: "规模类型",
            minWidth: 100,
            key: "scaleInfoName",
          },
          {
            title: "清粪模式",
            minWidth: 100,
            key: "manureProcessName",
          },
          {
            title: "粪污处理模式",
            minWidth: 120,
            key: "useTypeName",
          },
          {
            title: "粪污治理设施是否配套",
            minWidth: 180,
            key: "managementFacilitiesOk",
            render: (h, { row }) => {
              return (
                <span>{row.managementFacilitiesOk == 1 ? "是" : "否"}</span>
              );
            },
          },
          {
            title: "消纳土地面积(亩)",
            minWidth: 150,
            key: "consumptionScale",
          },
          {
            title: "养殖区域",
            minWidth: 100,
            key: "areaTypeName",
          },
          {
            title: "离河库最近距离(米)",
            minWidth: 160,
            key: "riverMeter",
          },
          {
            title: "监督人员",
            minWidth: 100,
            key: "supervisorName",
          },
          // {
          //   title: "联系电话",
          //   minWidth: 130,
          //   key: "supervisorPhone",
          // },
        ],
        data: [],
        loading: false,
      },
      page: {
        pageNo: 1,
        pageSize: 10,
        total: 0,
      },
      farmers: [],
      search_data: {},
      proList: [],
      manureProcess: [],
      sumHousingArea: 0,
      sumDesignNum: 0,
      sumConsumptionScale: 0,
    };
  },
  computed: {
    config() {
      let config = {
        filter: {
          loadModel: {
            url: "https://castoff.hogdata.cn/app/养殖户基础信息.xlsx",
          },
          loadEnter: {
            loadName: "导入",
            ca: "farmerBaseInfo_loadEnter",
          },
          loadExpend: {
            loadExpendName: "导出",
            ca: "farmerBaseInfo_loadExpend",
          },
          width: 220,
          filterBox: [
            {
              conditionName: "养殖户名称",
              component: "input",
              field: "farmerName",
              defaultValue: "",
            },
            {
              conditionName: "养殖地址",
              component: "input",
              field: "farmerAddress",
              defaultValue: "",
            },
            {
              conditionName: "畜禽种类",
              component: "select",
              field: "typeName",
              defaultValue: "",
              data: this.proList,
              parameterField: "name",
              showField: "name",
              filterable: true,
            },
            {
              conditionName: "业主姓名",
              component: "input",
              field: "name",
              defaultValue: "",
            },
            {
              conditionName: "联系电话",
              component: "input",
              field: "tel",
              defaultValue: "",
            },
            {
              conditionName: "养殖用途",
              component: "input",
              field: "application",
              defaultValue: "",
            },
            {
              conditionName: "清粪模式",
              component: "select",
              field: "manureProcess",
              defaultValue: "",
              data: this.manureProcess,
              parameterField: "id",
              showField: "name",
              filterable: true,
            },
          ],
        },
        // summary: [
        //   {
        //     sumName: "圈舍面积",
        //     sumVal: this.sumHousingArea,
        //     unit: "m²",
        //   },
        //   {
        //     sumName: "设计规模",
        //     sumVal: this.sumDesignNum,
        //     unit: "m²",
        //   },
        //   {
        //     sumName: "消纳土地",
        //     sumVal: this.sumConsumptionScale,
        //     unit: "m²",
        //   },
        // ],
        page: this.page,
      };
      return config;
    },
    ...mapState({
      user: (state) => state.user.user,
    }),
  },
  methods: {
    // getFarmers() {
    //   this.$post(this.$api.FAMRMING.LIST, {
    //     pageNo: this.page.pageNo,
    //     pageSize: this.page.pageSize,
    //   }).then((res) => {
    //     this.farmers = res.list;
    //   });
    // },
    pageChange(pageNo) {
      this.page.pageNo = pageNo;
      this.getList();
    },
    pageSizeChange(pageSize) {
      this.page.pageSize = pageSize;
      this.getList();
    },
    search(data) {
      this.search_data = { ...data };
      this.getList();
    },
    getList() {
      this.table.loading = true;
      this.$post(this.$api.FAMRMING.BASIC_INFORMATION, {
        pageNo: this.page.pageNo,
        pageSize: this.page.pageSize,
        ...this.search_data,
      })
        .then((res) => {
          this.table.data = res.list;
          this.page.total = +res.total;
          let num1 = 0;
          let num2 = 0;
          let num3 = 0;
          for (let i = 0; i < this.table.data.length; i++) {
            num1 += this.table.data[i].housingArea
              ? Number(this.table.data[i].housingArea)
              : 0;
            num2 += this.table.data[i].designNum
              ? Number(this.table.data[i].designNum)
              : 0;
            num3 += this.table.data[i].consumptionScale
              ? Number(this.table.data[i].consumptionScale)
              : 0;
          }
          this.sumHousingArea = num1;
          this.sumDesignNum = num2;
          this.sumConsumptionScale = num3;
        })
        .finally(() => {
          this.table.loading = false;
        });
    },
    getCategory() {
      this.$post(this.$api.PRODUCT_CATEGORY.LIST, {
        type: "2",
      }).then((res) => {
        this.proList = res.list;
      });
    },
    //清粪工艺
    getManureProcess() {
      this.$post(this.$api.SYS_CATEGORY.LIST, {
        pid: "25",
      }).then((res) => {
        this.manureProcess = res.list;
      });
    },
    loadEnter() {
      CU.loadEnter(this.$api.FAMRMING.BASIC_INFORMATION_UPLOAD, this.user).then(
        (res) => {
          console.log(res);
          if (res == "success") {
            this.$Message.success('导入成功')
            this.getList();
          }
        }
      );
    },
    loadExpend() {
      let columns = this.table.columns.map((item) => item.title);
      let data = this.table.data.map((row) => {
        let rowData = this.table.columns.map((column) => {
          if (column.key == "managementFacilitiesOk") {
            return row[column.key] == 1 ? "是" : "否";
          }
          return row[column.key];
        });
        return rowData;
      });
      CU.exportExcel(columns, data, "养殖户基础信息");
    },
    handleSummary({ columns, data }) {
      const sums = {};
      columns.forEach((column, index) => {
        const key = column.key;
        if (index === 0) {
          sums[key] = {
            key,
            value: "统计",
          };
          return;
        }
        const values = data.map((item) => Number(item[key]));
        if (
          column.key == "housingArea" ||
          column.key == "designNum" ||
          column.key == "consumptionScale"
        ) {
          if (!values.every((value) => isNaN(value))) {
            const v = values.reduce((prev, curr) => {
              const value = Number(curr);
              if (!isNaN(value)) {
                return prev + curr;
              } else {
                return prev;
              }
            }, 0);
            sums[key] = {
              key,
              value: v,
            };
          } else {
            sums[key] = {
              key,
              value: "",
            };
          }
        } else {
          sums[key] = {
            key,
            value: "",
          };
        }
      });
      return sums;
    },
  },
  mounted() {
    // this.getFarmers();
    this.getList();
    this.getCategory();
    this.getManureProcess();
  },
};
</script>

<style lang="less" scoped>
.farmers-basic-information {
  width: 100%;
  height: 100%;
}
</style>